<template>
  <div class="authority">
    <el-dialog :show-close="false" :visible.sync="dialogVisible" width="40%">
      <div class="dialogContent">
        <p>权限管理</p>
        <el-tree :data="data" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
          :props="defaultProps" :default-checked-keys="defaultData" @check-change="getCheckedNodes">
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { menuListAPI, setUserMenuAPI, userDetailAPI } from '@/api/user/user'
export default {
  data() {
    return {
      dialogVisible: false,
      data: [],
      defaultData: [], // 默认选中的数组
      defaultProps: {
        children: 'children',
        label: 'text'
      },
      choseList: [],
      userId: null, // 当前用户的id

    };
  },
  methods: {

    open(val) {
      this.userId = val;
      this.getMenuList();
      this.getUserDetail();
      this.dialogVisible = true;
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys());
    },
    // 获取用户选中的数据
    getCheckedNodes() {

      this.choseList = this.$refs.tree.getCheckedNodes();
      console.log(this.choseList);
    },
    // 获取菜单列表
    async getMenuList() {
      const res = await menuListAPI();
      console.log(res);
      if (res.code === 200) {
        this.data = res.data;
      } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
    },
    // 提交
    async submit() {
      let arr = [];
      this.choseList.forEach(item => {
        arr.push(item.id, item.pid)
      })
      // console.log(arr);
      // console.log(new Set(arr));
      let list = [...new Set(arr)]
      list = list.filter(item => {
        return item !== 0
      });
      let info = { id: this.userId, ids: [...list] }
      const res = await setUserMenuAPI(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success'
        });
        this.dialogVisible = false;
      } else {
        this.$message({
          showClose: true,
          message: '操作失败' + res.msg,
          type: 'error'
        });
      }
    },
    // 获取当前用户的权限
    async getUserDetail() {
      const res = await userDetailAPI({ id: this.userId });
      console.log(res);
      if (res.code === 200) {
        // this.defaultData = res.data.menuIds
        let list = res.data.menus;
        list.forEach(item => {
            if(item.children) {
              // this.defaultData.push(item.id)
              this.getMenuIdList(item.children);
            }
        })
        
        // console.log(this.defaultData)
      } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }

    },
    // 使用递归取出菜单权限的id
    getMenuIdList(val) {
      // console.log(val);

      if (val) {
        val.forEach(item => {
          // console.log(item.id)
          this.defaultData.push(item.menuId);
          
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.authority {
  .dialogContent {
    p {
      text-align: center;
      // font-size: 20px;
      font-weight: 600;
      margin-top: 0px;
    }
  }
}
</style>