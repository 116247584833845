<template>
    <div class="authorityList">
        <!-- 用户管理 -->
        <div class="searchBtn">
            <el-input placeholder="姓名" v-model="searchInfo.username" clearable size="small">
            </el-input>
            <el-input placeholder="手机号" type="number" v-model="searchInfo.mobile" clearable size="small">
            </el-input>
            <el-select v-model="searchInfo.status" placeholder="请选择状态" size="small">
                <el-option label="全部" :value="0">
                </el-option>
                <el-option label="正常" :value="1">
                </el-option>
                <el-option label="禁用" :value="2">
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="search">搜索</el-button>
            <el-button type="primary" size="small" @click="addUser(null)">新建</el-button>
            <el-button type="primary" size="small" @click="deleteUser">删除</el-button>
        </div>
        <div>
            <template>
                <el-table stripe :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="100">
                    </el-table-column>
                    <el-table-column prop="username" label="登录名">
                    </el-table-column>
                    <el-table-column prop="nickname" label="姓名">
                    </el-table-column>
                    <el-table-column prop="mobile" label="手机号">
                    </el-table-column>
                    <!-- <el-table-column prop="source" label="来源">
                    </el-table-column> -->
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <span v-show="scope.row.status === 1 || scope.row.status === '1'">正常</span>
                            <span v-show="scope.row.status === 2 || scope.row.status === '2'">禁用</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created" label="创建时间">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addUser(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="openAuthority(scope.row.id)">权限</el-button>
                            <el-button type="text" size="small" @click="updatePassword(scope.row.id)">重置密码</el-button>
                            <!-- <el-button type="text" size="small">激活</el-button>
                            <el-button type="text" size="small">冻结</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total,  prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
        <Authority ref="child"></Authority>
    </div>
</template>

<script>
// import UpdateUserVue from './UpdateUser.vue';
import Authority from './Authority.vue';
import { getUsersListAPI, deleteUserAPI, updateUserAPI } from '@/api/user/user'
// import {  } from '@/api/user/user';
export default {
    components: { Authority },
    data() {
        return {
            searchInfo: {
                status: null,
                username: null,
                mobile: null
            },
            // 分页
            paging: {
                p: 1,
                ps: 20,
                total: 0,
            },
            tableData: [], // 用户数据
            deleteList: []
        }
    },
    methods: {
        // 点击权限
        openAuthority(val) {
            this.$refs.child.open(val);
        },
        // 表格勾选
        handleSelectionChange(val) {
            // console.log(val);
            this.deleteList = val;
        },
        // 分页
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        // 获取用户列表
        async getUserList() {
            // JSON.stringify({pageSize: 20, pageNum: 1})
            let info = { pageSize: this.paging.ps, pageNum: this.paging.p, ...this.searchInfo }
            if (info.status === 0) {
                info.status = null
            }
            const res = await getUsersListAPI(JSON.stringify(info));

            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.list;
                this.paging.total = res.data.total;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
        },
        // 点击搜索
        search() {
            this.paging.p = 1;
            this.getUserList();
        },
        // 新增、编辑用户跳转
        addUser(val) {
            if (val !== null) {
                this.$router.push({
                    path: '/admin/user/editUser',
                    query: {
                        id: val
                    }
                })
            } else {
                this.$router.push('/admin/user/editUser')
            }
        },
        // 删除用户
        deleteUser() {
            if(this.deleteList.length > 0) {
                this.$confirm('确认删除该用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let arr = [];
                
                this.deleteList.forEach(item => {
                    arr.push(item.id)
                })
                console.log(arr)
                const res = await deleteUserAPI(arr);
                console.log(res)
                this.$message({
                    type: 'success',
                    showClose:true,
                    message: '操作成功!'
                });
                this.getUserList();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    showClose:true,
                    message: '已取消删除'
                });
            });
            } else {
                this.$message({
                    type: 'info',
                    showClose:true,
                    message: '请勾选！'
                });
            }
            
        },
        // 修改密码
        updatePassword(val) {
            this.$prompt('请输入新密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then( async({ value }) => {
                console.log(value)
                let info = { id: val, password: value }
                const res = await updateUserAPI(JSON.stringify(info));
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        showClose: true,
                        message: '操作成功！'
                    });
                } else {
                    this.$message({
                        type: 'success',
                        showClose: true,
                        message: '操作失败！' + res.msg
                    });
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '取消输入'
                });
            });
        }
    },
    created() {
        this.getUserList();
    }

}
</script>

<style></style>